import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  Row,
  Col,
  Button,
  CardFooter,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { APICALL } from "../../../helper/api/api";
import {
  imgBaseURL,
  toastifyError,
  toastifySuccess,
} from "../../../helper/Utility";
import AdminLoader from "../../components/AdminLoader";
import BackButton from "../BackButton";
import ConfirmationModal from "../ConfirmationModal";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { Checkbox } from "@mui/material";
import { Autocomplete, TextField, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../lang/i18n";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SchoolDetailsEdit = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imgList, setImgList] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const { t } = useTranslation();

  const childGradeSelect = [
    {
      title: "Pre-K",
      value: "Pre-K",
    },
    {
      title: "Kindergarten",
      value: "Kindergarten",
    },
    {
      title: "1st Grade",
      value: "1st Grade",
    },
    {
      title: "2nd Grade",
      value: "2nd Grade",
    },
    {
      title: "3rd Grade",
      value: "3rd Grade",
    },
    {
      title: "4th Grade",
      value: "4th Grade",
    },
    {
      title: "5th Grade",
      value: "5th Grade",
    },
    {
      title: "6th Grade",
      value: "6th Grade",
    },
    {
      title: "7th Grade",
      value: "7th Grade",
    },
    {
      title: "8th Grade",
      value: "8th Grade",
    },
    {
      title: "9th Grade",
      value: "9th Grade",
    },
    {
      title: "10th Grade",
      value: "10th Grade",
    },
    {
      title: "11th Grade",
      value: "11th Grade",
    },
    {
      title: "12th Grade",
      value: "12th Grade",
    },
  ];

  const curriculumList = [
    {
      title: "Ahli/National",
      value: "Ahli/National",
    },
    {
      title: "Special Needs",
      value: "Special Needs",
    },
    {
      title: "ISOSchools",
      value: "ISOSchools",
    },
    {
      title: "American",
      value: "American",
    },
    {
      title: "British",
      value: "British",
    },
    {
      title: "Australian",
      value: "Australian",
    },
    {
      title: "French",
      value: "French",
    },
    {
      title: "SABIS",
      value: "SABIS",
    },
    {
      title: "American Diploma",
      value: "American Diploma",
    },
    {
      title: "International Baccalaureate",
      value: "International Baccalaureate",
    },
    {
      title: "Indian",
      value: "Indian",
    },
    {
      title: "Filipino",
      value: "Filipino",
    },
    {
      title: "Pakistani",
      value: "Pakistani",
    },
    {
      title: "Egyptian",
      value: "Egyptian",
    },
    {
      title: "Sudanese",
      value: "Sudanese",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const performanceAndRankingList = [
    { title: "1", value: "1" },
    { title: "2", value: "2" },
    { title: "3", value: "3" },
    { title: "4", value: "4" },
    { title: "5", value: "5" },
  ];

  const schoolTypeList = [
    {
      title: "Boys ONLY",
      value: "Boys ONLY",
    },
    {
      title: "Girls ONLY",
      value: "Girls ONLY",
    },
    {
      title: "Boys and Girls",
      value: "Boys and Girls",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const budgetList = [
    {
      title: "Less than 20000 SAR",
      value: "Less than 20000 SAR",
    },
    {
      title: "Less than 50000 SAR",
      value: "Less than 50000 SAR",
    },
    {
      title: "Less than 90000 SAR",
      value: "Less than 90000 SAR",
    },
    {
      title: "More than 90000 SAR",
      value: "More than 90000 SAR",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const specialProgramsList = [
    {
      title: "Ahli/National",
      value: "Ahli/National",
    },
    {
      title: "International",
      value: "International",
    },
    {
      title: "Foreign",
      value: "Foreign",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const schoolSizeList = [
    {
      title: "KG 1-2-3",
      value: "KG 1-2-3",
    },
    {
      title: "KG & Primary",
      value: "KG & Primary",
    },
    {
      title: "KG & Primary & Middle",
      value: "KG & Primary & Middle",
    },
    {
      title: "KG & Primary & Middle & Secondary",
      value: "KG & Primary & Middle & Secondary",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const extracurricularActivitiesList = [
    {
      title: "Child Theatre",
      value: "Child Theatre",
    },
    {
      title: "Cultural/Heritage",
      value: "Cultural/Heritage",
    },
    {
      title: "Public Awareness Programme",
      value: "Public Awareness Programme",
    },
    {
      title: "Music and Choral",
      value: "Music and Choral",
    },
    {
      title: "Artwork and Handcrafts",
      value: "Artwork and Handcrafts",
    },
    {
      title: "Fitness",
      value: "Fitness",
    },
    {
      title: "Festivals and Fun-days",
      value: "Festivals and Fun-days",
    },
    {
      title: "Scientific and Exploration",
      value: "Scientific and Exploration",
    },
    {
      title: "Voluntary",
      value: "Voluntary",
    },
    {
      title: "National/Historical",
      value: "National/Historical",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const languagesOfferedList = [
    {
      title: "Arabic",
      value: "Arabic",
    },
    {
      title: "English",
      value: "English",
    },
    {
      title: "French",
      value: "French",
    },
    {
      title: "Deutsch",
      value: "Deutsch",
    },
    {
      title: "Chinese",
      value: "Chinese",
    },
    {
      title: "Spanish",
      value: "Spanish",
    },
    {
      title: "Italian",
      value: "Italian",
    },
    {
      title: "Portugalian",
      value: "Portugalian",
    },
    {
      title: "Japanese",
      value: "Japanese",
    },
    {
      title: "Russian",
      value: "Russian",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const specialNeedsSupportList = [
    {
      title: "Total integration",
      value: "Total integration",
    },
    {
      title: "Partial merging",
      value: "Partial merging",
    },
    {
      title: "Spatial merging",
      value: "Spatial merging",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const reputationAndAccreditationList = [
    {
      title: "Etimad",
      value: "Etimad",
    },
    {
      title: "Gognia",
      value: "Gognia",
    },
    {
      title: "IAO",
      value: "IAO",
    },
    {
      title: "ISO",
      value: "ISO",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const safetyAndEnvironmentList = [
    {
      title: "First Aid",
      value: "First Aid",
    },
    {
      title: "Fire Alarm",
      value: "Fire Alarm",
    },
    {
      title: "Fire Fight",
      value: "Fire Fight",
    },
    {
      title: "Emergency Training",
      value: "Emergency Training",
    },
    {
      title: "All",
      value: "All",
    },
  ];

  const handleMultipleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const deleteImage = async () => {
    setIsSubmit(true);
    try {
      const res = await APICALL("school/removeGalleryImage", "post", {
        fileName: selectedData?.fileName,
        id: data?.schoolDetails?.userId,
      });
      if (res?.status) {
        setIsSubmit(false);
        setModalShow(false);
        toastifySuccess(res?.message);
        const updatedImgList = imgList.filter(
          (img) => img?._id !== selectedData?._id
        );
        setImgList(updatedImgList);
      }
    } catch (error) {
      setIsSubmit(false);
      console.log(error);
    }
  };

  const [formData, setFormData] = useState({
    _id: data?._id,
    schoolName: "",
    aboutUs: "",
    quick_info: {
      contact_no: "",
      business_hours: "",
      since: "",
      website: "",
    },
    social_media: {
      facebook: "",
      instagram: "",
      twitter: "",
      youtube: "",
    },
    amenities: "",
    accreditation_certifications: "",
    programs_offered: "",
    admission_process: "",
    fee_structure: "",
    faculty_information: "",
    safety_features: "",
    school_calender: "",
    student_teacher_ratio: "",
    digital_infrastructure: "",
    nearby_landmarks: "",
    childs_grade_select: "",
    budget: "",
    special_programs: "",
    school_size: "",
    special_needs_support: "",
    reputation_accreditation: "",
    schoolType: "",
    performanceAndRanking: "",
    safety_environment: [],
    extracurricular_activities: [],
    languages_offered: [],
    curriculum: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");

      setFormData((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Initialize tags state to keep track of multiple fields
  const [tags, setTags] = useState({
    amenities: formData.amenities,
    programs_offered: formData.programs_offered,
    languages_offered: formData.languages_offered,
    special_programs: formData.special_programs,
    // Add other fields here as needed
  });

  // Update both formData and tags when the tags change
  const handleTagsChange = (fieldName, newTags) => {
    // Update the local tags state
    setTags((prevTags) => ({
      ...prevTags,
      [fieldName]: newTags,
    }));

    // Update formData with the new tag list for the corresponding field
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: newTags,
    }));
  };

  // Synchronize formData changes with the tags state (for example, when formData is pre-populated)
  useEffect(() => {
    setTags({
      amenities: formData.amenities || [],
      programs_offered: formData.programs_offered || [],
      languages_offered: formData.languages_offered || [],
      special_programs: formData.special_programs || [],
      // Add other fields here if needed
    });
  }, [formData]);
  const handleInputChange = (event, value) => {
    // Check if the value entered is new and not empty and add it to the tags array
    if (
      event.key === "Enter" &&
      value.trim() !== "" &&
      !tags.includes(value.trim())
    ) {
      const newTags = [...tags, value.trim()];
      setTags(newTags);
      handleChange({
        target: { name: "amenities", value: newTags },
      });
    }
  };

  useEffect(() => {
    const fetchData = () => {
      const initialData = {
        _id: data?._id,
        schoolName: data?.schoolDetails?.schoolName || "",
        aboutUs: data?.schoolDetails?.aboutUs || "",
        quick_info: {
          contact_no: data?.schoolDetails?.quick_info?.contact_no || "",
          business_hours: data?.schoolDetails?.quick_info?.business_hours || "",
          since: data?.schoolDetails?.quick_info?.since || "",
          website: data?.schoolDetails?.quick_info?.website || "",
        },
        social_media: {
          facebook: data?.schoolDetails?.social_media?.facebook || "",
          instagram: data?.schoolDetails?.social_media?.instagram || "",
          twitter: data?.schoolDetails?.social_media?.twitter || "",
          youtube: data?.schoolDetails?.social_media?.youtube || "",
        },
        amenities: data?.schoolDetails?.amenities || [],
        accreditation_certifications:
          data?.schoolDetails?.accreditation_certifications || "",
        programs_offered: data?.schoolDetails?.programs_offered || "",
        admission_process: data?.schoolDetails?.admission_process || "",
        fee_structure: data?.schoolDetails?.fee_structure || "",
        faculty_information: data?.schoolDetails?.faculty_information || "",
        safety_features: data?.schoolDetails?.safety_features || "",
        school_calender: data?.schoolDetails?.school_calender || "",
        student_teacher_ratio: data?.schoolDetails?.student_teacher_ratio || "",
        digital_infrastructure:
          data?.schoolDetails?.digital_infrastructure || "",
        nearby_landmarks: data?.schoolDetails?.nearby_landmarks || "",
        childs_grade_select: data?.schoolDetails?.childs_grade_select || "",
        budget: data?.schoolDetails?.budget || "",
        special_programs: data?.schoolDetails?.special_programs || "",
        school_size: data?.schoolDetails?.school_size || "",
        special_needs_support: data?.schoolDetails?.special_needs_support || "",
        reputation_accreditation:
          data?.schoolDetails?.reputation_accreditation || "",
        schoolType: data?.schoolDetails?.schoolType || "",
        performanceAndRanking: data?.schoolDetails?.performanceAndRanking || "",

        safety_environment: data?.schoolDetails?.safety_environment || [],
        curriculum: data?.schoolDetails?.curriculum || [],
        extracurricular_activities:
          data?.schoolDetails?.extracurricular_activities || [],
        languages_offered: data?.schoolDetails?.languages_offered || [],
      };
      console.log("initialData", initialData);
      setFormData(initialData);
      setImgList(data?.schoolDetails?.gallery);
    };

    fetchData();
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await APICALL("school/updateSchoolDetails", "post", formData);
      if (res?.status) {
        setLoading(false);
        navigate("/admin/list/School");
        toastifySuccess(res?.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [formImageData, setFormImageData] = useState({
    logo: null,
    gallery: [],
  });

  const imageHandleChange = (e) => {
    const { name, files } = e.target;

    if (name === "gallery") {
      setFormImageData((prevState) => ({
        ...prevState,
        gallery: Array.from(files),
      }));
      return;
    }

    if (name === "logo") {
      setFormImageData((prevState) => ({
        ...prevState,
        logo: files[0],
      }));
      return;
    }
  };

  const handleImagesSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const ImagePerms = new FormData();
    ImagePerms.append("id", data?.schoolDetails?.userId);

    if (formImageData.gallery.length === 0 && !formImageData.logo) {
      setLoading(false);
      toastifyError(
        "Please upload at least one field (either gallery or logo)"
      );
      return;
    }

    if (formImageData.gallery.length > 0) {
      formImageData.gallery.forEach((file) => {
        ImagePerms.append("gallery", file);
      });
    }

    if (formImageData.logo) {
      ImagePerms.append("logo", formImageData.logo);
    }

    try {
      const res = await APICALL(
        "school/updateSchoolGallery",
        "post",
        ImagePerms
      );

      if (res?.status) {
        setLoading(false);
        navigate(-1);
        toastifySuccess(res?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toastifyError("Something went wrong");
    }
  };

  return (
    <>
      <Row>
        {loading ? (
          <AdminLoader />
        ) : (
          <>
            <Col md={8}>
              <Card className="card-cusotom card">
                <CardHeader>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <BackButton />
                      <h2 className="title-admins-table m-0">{t('Edit Details')}</h2>
                    </div>
                  </div>
                </CardHeader>
                <>
                  <Form onSubmit={handleSubmit}>
                    <CardBody>
                      <div className="cutoms-login-artist">
                        <Row>
                          <div className="col-md-12">
                            <Row>
                              <h5 className="mb-4">
                                <b>{t('About School')}</b>
                              </h5>

                              <Col md={3} className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="schoolName"
                                >
                                  <Form.Label>{t('School Name')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="schoolName"
                                    value={formData.schoolName}
                                    onChange={handleChange}
                                    placeholder={t('School Name')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3} className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="contact_no"
                                >
                                  <Form.Label>{t('Contact No')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="quick_info.contact_no"
                                    value={formData.quick_info.contact_no}
                                    onChange={handleChange}
                                    placeholder={t('Contact No')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3} className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="business_hours"
                                >
                                  <Form.Label>{t('Business Hours')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="quick_info.business_hours"
                                    value={formData.quick_info.business_hours}
                                    onChange={handleChange}
                                    placeholder={t('Business Hours')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3} className="mb-3">
                                <Form.Group className="mb-3" controlId="since">
                                  <Form.Label>{t('Since')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="quick_info.since"
                                    value={formData.quick_info.since}
                                    onChange={handleChange}
                                    placeholder={t('Since')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={12} className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="aboutUs"
                                >
                                  <Form.Label>{t('About Us')}</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="aboutUs"
                                    value={formData.aboutUs}
                                    onChange={handleChange}
                                    placeholder={t('About Us')}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>

                          <div className="col-md-12">
                            <h5 className="mb-4">
                              <b>{t('Social Links')}</b>
                            </h5>
                            <Row className="row row-cols-1 row-cols-sm-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 g-3 pt-1 ">
                              <Col className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="website"
                                >
                                  <Form.Label>{t('Website')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="quick_info.website"
                                    value={formData.quick_info.website}
                                    onChange={handleChange}
                                    placeholder={t('Website')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="facebook"
                                >
                                  <Form.Label>{t('Facebook')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="social_media.facebook"
                                    value={formData.social_media.facebook}
                                    onChange={handleChange}
                                    placeholder={t('Facebook')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="instagram"
                                >
                                  <Form.Label>{t('Instagram')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="social_media.instagram"
                                    value={formData.social_media.instagram}
                                    onChange={handleChange}
                                    placeholder={t('Instagram')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="twitter"
                                >
                                  <Form.Label>{t('Twitter')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="social_media.twitter"
                                    value={formData.social_media.twitter}
                                    onChange={handleChange}
                                    placeholder={t('Twitter')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col className="mb-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="youtube"
                                >
                                  <Form.Label>{t('YouTube')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="social_media.youtube"
                                    value={formData.social_media.youtube}
                                    onChange={handleChange}
                                    placeholder={t('YouTube')}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>

                          <div className="col-md-12">
                            <h5 className="mb-4">
                              <b>{t('All Information')}</b>
                            </h5>
                            <Row>
                              {/* <Col md={3} className="mb-4">
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  options={[]} // You can provide predefined options here if needed
                                  value={tags} // Use the merged value of old tags and new tags
                                  onChange={handleTagsChange} // Handle changes to the tags
                                  onInputChange={handleInputChange} // Handle input change when typing
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        key={index}
                                        label={option}
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Amenities"
                                      placeholder="Add amenitie"
                                    />
                                  )}
                                />
                              </Col> */}

                              <Col md={12} className="mb-4">
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  options={[]} // You can provide predefined options here if needed
                                  value={formData.amenities} // Use tags from the state
                                  onChange={(event, newValue) =>
                                    handleTagsChange("amenities", newValue)
                                  } // Update tags and formData
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        key={index}
                                        label={option}
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label={t('Amenities')}
                                      placeholder={t('Amenities')}
                                      className="multi_add"
                                    />
                                  )}
                                />
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="accreditation_certifications"
                                >
                                  <Form.Label>
                                    {t('Accreditation Certifications')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="accreditation_certifications"
                                    value={
                                      formData.accreditation_certifications
                                    }
                                    onChange={handleChange}
                                    placeholder={t('Accreditation Certifications')}
                                  />
                                </Form.Group>
                              </Col>

                              <Col md={9} className="mb-4">
                                <label className="mb-1">{t('Programs Offered')}</label>
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  options={[]} // You can provide predefined options here if needed
                                  value={formData.programs_offered} // Use tags from the state
                                  onChange={(event, newValue) =>
                                    handleTagsChange(
                                      "programs_offered",
                                      newValue
                                    )
                                  } // Update tags and formData
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        key={index}
                                        label={option}
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label={t('Programs Offered')}
                                      placeholder={t('Programs Offered')}
                                      className="multi_add"
                                    />
                                  )}
                                />
                              </Col>
                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="admission_process"
                                >
                                  <Form.Label>{t('Admission Process')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="admission_process"
                                    value={formData.admission_process}
                                    onChange={handleChange}
                                    placeholder={t('Admission Process')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="fee_structure"
                                >
                                  <Form.Label>{t('Fee Structure')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="fee_structure"
                                    value={formData.fee_structure}
                                    onChange={handleChange}
                                    placeholder={t('Fee Structure')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="faculty_information"
                                >
                                  <Form.Label>{t('Faculty Information')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="faculty_information"
                                    value={formData.faculty_information}
                                    onChange={handleChange}
                                    placeholder={t('Faculty Information')}
                                  />
                                </Form.Group>
                              </Col>

                              <Col md={12} className="mb-4">
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  options={[]} // You can provide predefined options here if needed
                                  value={formData.safety_features} // Use tags from the state
                                  onChange={(event, newValue) =>
                                    handleTagsChange(
                                      "safety_features",
                                      newValue
                                    )
                                  } // Update tags and formData
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        key={index}
                                        label={option}
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label={t('Safety Features')}
                                      placeholder={t('Safety Features')}
                                      className="multi_add"
                                    />
                                  )}
                                />
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="school_calender"
                                >
                                  <Form.Label>{t('School Calendar')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="school_calender"
                                    value={formData.school_calender}
                                    onChange={handleChange}
                                    placeholder={t('School Calendar')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="student_teacher_ratio"
                                >
                                  <Form.Label>{t('Student-Teacher Ratio')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="student_teacher_ratio"
                                    value={formData.student_teacher_ratio}
                                    onChange={handleChange}
                                    placeholder={t('Student-Teacher Ratio')}
                                  />
                                </Form.Group>
                              </Col>

                              <Col md={6} className="mb-4">
                                <label className="mb-1">
                                  {t('Digital Infrastructure')}
                                </label>
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  options={[]} // You can provide predefined options here if needed
                                  value={formData.digital_infrastructure} // Use tags from the state
                                  onChange={(event, newValue) =>
                                    handleTagsChange(
                                      "digital_infrastructure",
                                      newValue
                                    )
                                  } // Update tags and formData
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        key={index}
                                        label={option}
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label={t('Digital Infrastructure')}
                                      placeholder={t('Digital Infrastructure')}
                                      className="multi_add"
                                    />
                                  )}
                                />
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="nearby_landmarks"
                                >
                                  <Form.Label>{t('Nearby Landmarks')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="nearby_landmarks"
                                    value={formData.nearby_landmarks}
                                    onChange={handleChange}
                                    placeholder={t('Nearby Landmarks')}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="childs_grade_select"
                                >
                                  <Form.Label>{t("Childs Grade Select")}</Form.Label>
                                  <Form.Select
                                    name="childs_grade_select"
                                    onChange={handleChange}
                                    aria-label={t("Childs Grade Select")}
                                  >
                                    {childGradeSelect.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                        selected={
                                          item.value ===
                                          formData.childs_grade_select
                                        }
                                      >
                                        {t(item.title)}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="extracurricular_activities"
                                >
                                  <Form.Label>
                                    {t('Extracurricular Activities')}
                                  </Form.Label>
                                  <FormControl className="w-100 global_check">
                                    <InputLabel id="extracurricular_activities-checkbox-label">
                                      {t('Extracurricular Activities')}
                                    </InputLabel>
                                    <Select
                                      labelId="extracurricular_activities-checkbox-label"
                                      id="extracurricular_activities"
                                      name="extracurricular_activities"
                                      className="multi_check_input"
                                      multiple
                                      value={
                                        formData.extracurricular_activities
                                      }
                                      onChange={handleMultipleChange}
                                      input={<OutlinedInput label="Tag" />}
                                      renderValue={(selected) =>
                                        selected.map((value) => t(value)).join(", ")
                                      }
                                      MenuProps={MenuProps}
                                    >
                                      {extracurricularActivitiesList.map(
                                        (item) => (
                                          <MenuItem
                                            key={item.title}
                                            value={item.value}
                                          >
                                            <Checkbox
                                              checked={formData.extracurricular_activities.includes(
                                                item.value
                                              )}
                                            />
                                            <ListItemText
                                              primary={t(item.value)}
                                            />
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group className="mb-3" controlId="budget">
                                  <Form.Label>{t('Budget')}</Form.Label>
                                  <Form.Select
                                    name="budget"
                                    value={formData.budget}
                                    onChange={handleChange}
                                    aria-label={t('Budget')}
                                  >
                                    {budgetList.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                        selected={
                                          item.value === formData.budget
                                        }
                                      >
                                        {t(item.title)}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="special_programs"
                                >
                                  <Form.Label>{t('Special Programs')}</Form.Label>
                                  <Form.Select
                                    name="special_programs"
                                    value={formData.special_programs}
                                    onChange={handleChange}
                                    aria-label={t('Special Programs')}
                                  >
                                    {specialProgramsList.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                        selected={
                                          item.value ===
                                          formData.special_programs
                                        }
                                      >
                                        {t(item.title)}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="school_size"
                                >
                                  <Form.Label>{t('School Size')}</Form.Label>
                                  <Form.Select
                                    name="school_size"
                                    value={formData.school_size}
                                    onChange={handleChange}
                                    aria-label={t('School Size')}
                                  >
                                    <option value="">Select School Size</option>{" "}
                                    {schoolSizeList.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                        selected={
                                          item.value === formData.school_size
                                        }
                                      >
                                        {t(item.title)}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="languages_offered"
                                >
                                  <Form.Label>{t('Languages Offered')}</Form.Label>
                                  <FormControl className="w-100 global_check">
                                    <InputLabel id="languages_offered-checkbox-label">
                                      {t('Languages Offered')}
                                    </InputLabel>
                                    <Select
                                      labelId="languages_offered-checkbox-label"
                                      id="languages_offered"
                                      className="multi_check_input"
                                      name="languages_offered"
                                      multiple
                                      value={formData.languages_offered}
                                      onChange={handleMultipleChange}
                                      input={
                                        <OutlinedInput label={t('Languages Offered')} />
                                      }
                                      renderValue={(selected) =>
                                        selected.map((value) => t(value)).join(", ")
                                      }
                                      MenuProps={MenuProps}
                                    >
                                      {/* Define an array for language options */}
                                      {languagesOfferedList.map((item) => (
                                        <MenuItem
                                          key={item.value}
                                          value={item.value}
                                        >
                                          <Checkbox
                                            checked={formData.languages_offered.includes(
                                              item.value
                                            )}
                                          />
                                          <ListItemText primary={t(item.title)} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="special_needs_support"
                                >
                                  <Form.Label>{t('Special Needs Support')}</Form.Label>
                                  <Form.Select
                                    name="special_needs_support"
                                    value={formData.special_needs_support}
                                    onChange={handleChange}
                                    aria-label={t('Special Needs Support')}
                                  >
                                    {specialNeedsSupportList.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                        selected={
                                          item.value ===
                                          formData.special_needs_support
                                        }
                                      >
                                        {t(item.title)}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="reputation_accreditation"
                                >
                                  <Form.Label>
                                    {t('Reputation & Accreditation')}
                                  </Form.Label>
                                  <Form.Select
                                    name="reputation_accreditation"
                                    value={formData.reputation_accreditation}
                                    onChange={handleChange}
                                    aria-label={t('Reputation & Accreditation')}
                                  >
                                    {reputationAndAccreditationList.map(
                                      (item) => (
                                        <option
                                          key={item.value}
                                          value={item.value}
                                          selected={
                                            item.value ===
                                            formData.reputation_accreditation
                                          }
                                        >
                                          {t(item.title)}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="safety_environment"
                                >
                                  <Form.Label>{t('Safety Environment')}</Form.Label>
                                  <FormControl className="w-100 global_check">
                                    <InputLabel id="safety_environment-checkbox-label">
                                      {t('Safety Environment')}
                                    </InputLabel>
                                    <Select
                                      labelId="safety_environment-checkbox-label"
                                      id="safety_environment"
                                      name="safety_environment"
                                      className="multi_check_input"
                                      multiple
                                      value={formData.safety_environment}
                                      onChange={handleMultipleChange}
                                      input={<OutlinedInput label="Tag" />}
                                      renderValue={(selected) =>
                                        selected.map((value) => t(value)).join(", ")
                                      }
                                      MenuProps={MenuProps}
                                    >
                                      {safetyAndEnvironmentList.map((item) => (
                                        <MenuItem
                                          key={item.title}
                                          value={item.value}
                                        >
                                          <Checkbox
                                            checked={formData.safety_environment.includes(
                                              item.value
                                            )}
                                          />
                                          <ListItemText primary={t(item.value)} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="schoolType"
                                >
                                  <Form.Label>{t('School Type')}</Form.Label>
                                  <Form.Select
                                    name="schoolType"
                                    value={formData.schoolType}
                                    onChange={handleChange}
                                    aria-label={t('School Type')}
                                  >
                                    {schoolTypeList.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                        selected={
                                          item.value === formData.schoolType
                                        }
                                      >
                                        {t(item.title)}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="curriculum"
                                >
                                  <Form.Label>{t('Curriculum')}</Form.Label>
                                  <FormControl className="w-100 global_check">
                                    <InputLabel id="curriculum-checkbox-label">
                                      {t('Curriculum')}
                                    </InputLabel>
                                    <Select
                                      labelId="curriculum-checkbox-label"
                                      id="curriculum"
                                      name="curriculum"
                                      className="multi_check_input"
                                      multiple
                                      value={formData.curriculum}
                                      onChange={handleMultipleChange}
                                      input={
                                        <OutlinedInput label="Curriculum" />
                                      }
                                      renderValue={(selected) =>
                                        selected.map((value) => t(value)).join(", ")
                                      }
                                      MenuProps={MenuProps}
                                    >
                                      {curriculumList.map((item) => (
                                        <MenuItem
                                          key={item.title}
                                          value={item.value}
                                        >
                                          <Checkbox
                                            checked={formData.curriculum.includes(
                                              item.value
                                            )}
                                          />
                                          <ListItemText primary={t(item.value)} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Form.Group>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Group
                                  className="mb-3"
                                  controlId="performanceAndRanking"
                                >
                                  <Form.Label>
                                    {t('Performance And Ranking')}
                                  </Form.Label>
                                  <Form.Select
                                    name="performanceAndRanking"
                                    value={formData.performanceAndRanking}
                                    onChange={handleChange}
                                    aria-label={t('Performance And Ranking')}
                                  >
                                    {performanceAndRankingList.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                        selected={
                                          item.value ===
                                          formData.performanceAndRanking
                                        }
                                      >
                                        {item.title}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </div>
                      <Row>
                        <Col md={12} className="text-end">
                          <Button
                            type="submit"
                            className="artist-btn btn btn-primary"
                          >
                            {t('Update')}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="card-cusotom card">
                <CardHeader>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <h2 className="title-admins-table m-0">{t('Upload Images')}</h2>
                    </div>
                  </div>
                </CardHeader>
                <Form onSubmit={handleImagesSubmit}>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <div className="form-group">
                          <label>{t('Logo')}</label>
                          <input
                            type="file"
                            onChange={imageHandleChange}
                            className="form-control"
                            name="logo"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          <label>{t('Gallary')}</label>
                          <input
                            type="file"
                            multiple
                            className="form-control"
                            onChange={imageHandleChange}
                            name="gallery"
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      type="submit"
                      className="global_btn btn btn-success"
                      disabled={!data?.schoolDetails?.userId}
                    >
                      {t('Upload')}
                    </Button>
                  </CardFooter>
                </Form>
              </Card>

              <Card className="card-cusotom card mt-3">
                <CardHeader>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <h2 className="title-admins-table m-0">{t('Gallery')}</h2>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {data?.schoolDetails?.gallery.length ? (
                    <Row className="mb-md-3 mb-2">
                      {imgList?.map((item) => (
                        <Col lg={6} key={item._id}>
                          <div className="view-admin-detials text-center mb-3 image_box">
                            <button
                              type="button"
                              onClick={() => {
                                setSelectedData(item);
                                setModalShow(true);
                              }}
                              className="trash_btn"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                            {item.fileType === "video" ? (
                              <video controls>
                                <source
                                  src={imgBaseURL() + item.fileName}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img
                                src={imgBaseURL() + item.fileName}
                                alt={imgBaseURL() + item.fileName}
                                className="image_ui"
                                width="100%"
                              />
                            )}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <h5 className="text-center">Doc Not Found</h5>
                  )}
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Row>

      <ConfirmationModal
        {...{ modalShow, setModalShow, isSubmit }}
        fun={deleteImage}
        closeBtnName={t('Close')} 
        actionBtnName={t('Delete')}
        title={t('Confirmation Modal')}
        body={t('Are you sure you want to Delete this') + " ?"}
      />
    </>
  );
};

export default SchoolDetailsEdit;
