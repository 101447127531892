import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import eng from "./eng.json";
import arabic from "./arabic.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: eng },
    ar: { translation: arabic },
  },
  lng: "en", // initial language
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

// Function to update the body class
const updateBodyClass = (lng) => {
  if (lng === "ar") {
    document.body.classList.add("rtl");
  } else {
    document.body.classList.remove("rtl");
  }
};

// Initial setup
updateBodyClass(i18n.language);

// Listen for language changes
i18n.on("languageChanged", (lng) => {
  updateBodyClass(lng);
});

export default i18n;
