import React from 'react';
import './PrivacyPolicy.css'; // Importing the CSS file

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <div className="privacy-policy">
                <h1>Privacy Policy</h1>
                <p>
                    At SJL, we take your privacy seriously and are committed to safeguarding your personal data. This Privacy Policy explains how we collect, use, and protect the personal data of users, specifically related to the roles of schools, transports, and parents, when interacting with our platform.
                </p>

                <h2>1. Introduction</h2>
                <p>
                    SJL is dedicated to providing services for schools, transport companies, and parents. We collect and manage personal information to ensure the proper functioning of our services, including school administration, transport logistics, and parent communication. This Privacy Policy outlines how we handle the personal data of each role.
                </p>

                <h2>2. Data Collection</h2>
                <p>We collect the following types of personal data depending on the role:</p>

                <h3>A. Schools</h3>
                <ul>
                    <li><strong>Personal Information</strong>: School name, address, contact details (phone number, email), registration information.</li>
                    <li><strong>Student Data</strong>: Information about students enrolled in the school, such as names, grades, attendance, and academic progress.</li>
                    <li><strong>Staff Data</strong>: Details of teaching and administrative staff, including names, contact information, roles, and working hours.</li>
                </ul>

                <h3>B. Transports</h3>
                <ul>
                    <li><strong>Company Information</strong>: Transport provider company name, registration details, and contact information.</li>
                    <li><strong>Vehicle Information</strong>: Data related to transport vehicles, including vehicle registration numbers, types, and service areas.</li>
                    <li><strong>Route and Scheduling Data</strong>: Information about transport routes, schedules, and driver details.</li>
                    <li><strong>User Data</strong>: Information about transport personnel, such as names, contact information, and roles.</li>
                </ul>

                <h3>C. Parents</h3>
                <ul>
                    <li><strong>Parent Information</strong>: Names, addresses, phone numbers, and email addresses of parents or guardians.</li>
                    <li><strong>Student-Related Data</strong>: Information about the students that parents are responsible for, including names, grade levels, and academic performance.</li>
                    <li><strong>Communication Data</strong>: Records of communications with the school or transport service (e.g., emails, phone calls).</li>
                </ul>

                <h2>3. Use of Data</h2>
                <p>We use the collected data for the following purposes:</p>
                <ul>
                    <li><strong>Schools</strong>: To manage student records, communicate with parents, track student progress, and manage school operations.</li>
                    <li><strong>Transports</strong>: To manage transport logistics, including vehicle schedules, routes, and driver assignments, and to ensure safe and timely transportation of students.</li>
                    <li><strong>Parents</strong>: To maintain communication with parents regarding their children’s education, school activities, and transport services.</li>
                </ul>

                <h2>4. Data Protection</h2>
                <p>
                    SJL employs reasonable technical and organizational measures to protect personal data from unauthorized access, alteration, disclosure, or destruction. These measures include:
                </p>
                <ul>
                    <li>Secure servers and databases.</li>
                    <li>Encryption of sensitive data.</li>
                    <li>Access controls to limit who can view or modify personal data.</li>
                </ul>

                <h2>5. Sharing of Data</h2>
                <p>We do not sell, rent, or share personal data with third parties for marketing purposes. However, we may share personal data in the following circumstances:</p>
                <ul>
                    <li><strong>With service providers</strong>: To enable us to provide our services (e.g., transport companies, IT providers).</li>
                    <li><strong>With authorities</strong>: When required by law or for legal obligations (e.g., reporting to educational authorities).</li>
                    <li><strong>With partners</strong>: In cases of collaborative efforts (e.g., educational initiatives) where data sharing is necessary.</li>
                </ul>

                <h2>6. User Rights</h2>
                <p>
                    Depending on your role and applicable law, you may have the following rights regarding your personal data:
                </p>
                <ul>
                    <li><strong>Access</strong>: The right to request a copy of the personal data we hold about you.</li>
                    <li><strong>Correction</strong>: The right to correct any inaccurate or incomplete data.</li>
                    <li><strong>Deletion</strong>: The right to request the deletion of your data, subject to legal obligations.</li>
                    <li><strong>Restriction</strong>: The right to restrict the processing of your data under certain circumstances.</li>
                    <li><strong>Data Portability</strong>: The right to request the transfer of your data to another service provider.</li>
                </ul>

                <p>To exercise these rights, please contact us at [contact information].</p>

                <h2>7. Data Retention</h2>
                <p>
                    We will retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. After this period, your data will be securely deleted or anonymized.
                </p>

                <h2>8. Cookies and Tracking Technologies</h2>
                <p>
                    We use cookies and similar tracking technologies to enhance the user experience and analyze how our platform is used. These technologies help us remember your preferences and improve our services.
                </p>
                <p>
                    You can manage your cookie preferences in your browser settings. Please note that disabling cookies may affect the functionality of our platform.
                </p>

                <h2>9. Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
